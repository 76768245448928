import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../modules/auth/services/token-storage.service';

//Servicio para Injectar en cada peticion HTTP el Header + Token
const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthClientInterceptor implements HttpInterceptor {
  constructor(private token: TokenStorageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this.token.getTokenClient();

    // No setear token al Login
    if (
      token != null &&
      req.url != 'http://162.240.150.113:5001/mg_meals_api/Auth/login'
    ) {
      const accessToken = token.access_token;
      authReq = req.clone({
        headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + accessToken),
      });
    }
    return next.handle(authReq);
  }
}
