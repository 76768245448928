import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environments';
import { Observable, catchError, map, throwError } from 'rxjs';
import { ConfirmSubscriptionRequest, ProcessSubscriptionRequest, ProcessSubscriptionResponse, Subscription, SubscriptionClientResponse, SubscriptionResponse } from '../helpers/interfaces/api/subscription-api.interface';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private baseUrl: string = environment.baseUrl;

  public subscription: Subscription[] = [];

  constructor(private http: HttpClient) {}

  getSubscriptions(): Observable<SubscriptionResponse> {
    const url = `${this.baseUrl}/Operaciones/GetSubscriptionsDetail`;

    return this.http.get<SubscriptionResponse>(url).pipe(
      map((data) => data),
      catchError(() =>
        throwError(() => 'Ha ocurrido un error, intente nuevamente')
      )
    );
  }

  processSubscription(
    request: ProcessSubscriptionRequest
  ): Observable<ProcessSubscriptionResponse> {
    const url = `${this.baseUrl}/operacionesClient/processSubscription`;

    return this.http.post<ProcessSubscriptionResponse>(url, request).pipe(
      catchError((error) => {
        return throwError('Ha ocurrido un error, por favor intenta de nuevo');
      })
    );
  }

  confirmSubscription(
    request: ConfirmSubscriptionRequest
  ): Observable<ProcessSubscriptionResponse> {
    const url = `${this.baseUrl}/operacionesClient/confirmSubscription`;

    return this.http.post<ProcessSubscriptionResponse>(url, request).pipe(
      catchError((error) => {
        return throwError('Ha ocurrido un error, por favor intenta de nuevo');
      })
    );
  }

  cancelSubscription(request: ConfirmSubscriptionRequest): Observable<any> {
    const url = `${this.baseUrl}/operacionesClient/cancelSubscription`;

    return this.http.post<any>(url, request).pipe(
      catchError((error) => {
        return throwError('Ha ocurrido un error, por favor intenta de nuevo');
      })
    );
  }

  getSubscription(idClient: number): Observable<SubscriptionClientResponse> {
    const url = `${this.baseUrl}/operacionesClient/GetSubscriptionById`;

    const params = new HttpParams().set('idCliente', idClient);

    return this.http
      .get<SubscriptionClientResponse>(url, { params })
      .pipe(
        catchError(() =>
          throwError(() => 'Ha ocurrido un error, intente nuevamente')
        )
      );
  }
}
